@import "../../styles/variables.scss";

.filterModal {
  @apply flex flex-col px-[32px] py-[55px];
  width: 100%;
  overflow-y: auto;

  .title {
    @apply flex justify-between pb-[12px];
    border-bottom: 1px solid #D6D6D6;

    span {
      color: #232226;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }
  }

  .header {
    color: #232226;
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  .desc {
    color: #232226;
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  .button {
    padding: 12px 24px;
    border-radius: 8px;

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;

    &.cancel {
      background: #fff;
      border: 1px solid #FD7571;
      color: #FD7571;
    }

    &.save {
      background: #05D490;
      color: #fff;
      &.disabledBtn{
        background: #4f4f4f;
      }
    }

  }

}


.selection {
  .MuiAutocomplete-root {
    width: 100% !important;
  }

  .MuiInputBase-root {
    border-radius: 10px !important;
  }

  fieldset {
    border-color: $color-purple !important;
  }

  legend {
    width: 0px !important;
    padding: 0px !important;
  }

  .MuiInputLabel-shrink {
    color: transparent !important;
  }

  .MuiAutocomplete-endAdornment {
    padding-right: 24px !important;
  }

  .MuiInputBase-inputAdornedEnd {
    padding-right: 16px !important;
    color: #6D6D6D !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }

  .MuiAutocomplete-option {
    min-height: 52px !important;
    background-color: white !important;
    color: black !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    &.Mui-focused {
      background-color: $color-purple !important;
      color: white !important;
    }
  }

  .MuiAutocomplete-popper {
    .MuiPaper-root {
      border-radius: 0px 0px 10px 10px !important;
      border-right: 2px solid $color-purple !important;
      border-bottom: 2px solid $color-purple !important;
      border-left: 2px solid $color-purple !important;
    }
  }

  .MuiAutocomplete-listbox {
    padding: 0px;
  }

  .Mui-expanded {
    fieldset {
      border-bottom: 0px !important;
    }

    .MuiInputBase-root {
      border-radius: 10px 10px 0px 0px !important;
    }
  }
}
