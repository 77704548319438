
.firmName {
  color: var(--color-palette-text-1, #232226);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-decoration: underline;
}

.location {
  @apply flex gap-[4px];

  color: var(--color-palette-sm-5, #999);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.jobTitle {
  color: var(--color-palette-text-1, #232226);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.jobDescription {
  color: var(--color-palette-text-1, #232226);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  .colored {
    color: var(--color-palette-main, #5658FF);
  }
}

.detailUpText {
  color: var(--color-palette-text-1, #232226);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.detailBottomText {
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 160% */
}
