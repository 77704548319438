@import "../../styles/variables.scss";

.input-select {

  position: relative;

  .selectWrapper {
    position: relative;
    border-radius: 10px !important;
    min-height: 44px;
    height: auto;
    max-height: 250px;
    overflow-y: scroll;
    padding: 8px;
    width: 100% !important;
    border-color: $color-purple !important;
    box-shadow: none !important;

    &::-webkit-scrollbar {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: $color-lightest-purple;
      border-radius: 16px;
      width: 10px;
    }

    .MuiSvgIcon-root {
      width: 20px;
      height: 20px;
    }

    div {
      border-radius: 16px;
      height: 26px;
      background-color: $color-lightest-purple;
    }
  }

  ul {
    width: 100% !important;
  }

  li {
    height: 44px;

    span {
      display: flex;
      align-items: center;
    }
  }

  li.Mui-focused {
    color: white !important;
    background-color: $color-purple !important;

  }

  .MuiAutocomplete-root {
    width: 100% !important;
    min-width: 150px;
    height: 44px;
  }

  .MuiInputBase-root {
    border-radius: 10px !important;
  }

  fieldset {
    border-color: $color-purple !important;
  }

  legend {
    width: 0px !important;
    padding: 0px !important;
  }

  .MuiInputLabel-shrink {
    color: transparent !important;
  }

  .MuiAutocomplete-endAdornment {
    padding-right: 16px !important;
  }

  .MuiInputBase-inputAdornedEnd {
    padding-right: 16px !important;
    color: #6D6D6D !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }

  .MuiInputLabel-root {
    color: #6D6D6D;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }

  .MuiAutocomplete-option {
    min-height: 52px !important;
    background-color: white !important;
    color: black !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    &.Mui-focused {
      background-color: $color-purple !important;
      color: white !important;
    }
  }

  .MuiAutocomplete-popper {
    .MuiPaper-root {
      border-radius: 10px 10px 10px 10px !important;
      border-top: 2px solid $color-purple !important;
      border-right: 2px solid $color-purple !important;
      border-bottom: 2px solid $color-purple !important;
      border-left: 2px solid $color-purple !important;
    }
  }

  .MuiAutocomplete-listbox {
    padding: 0px;
  }

  .Mui-expanded {
    fieldset {
      //border-bottom: 0px !important;
    }

    .MuiInputBase-root {
      border-radius: 10px 10px 10px 10px !important;
    }
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .MuiAutocomplete-root:has(.Mui-disabled) {
    & label {
      background-color: transparent !important;
    }
  }

  .Mui-disabled {
    background-color: $color-lightest-grey;


    & fieldset {
      border-color: $color-light-grey !important;
    }
  }

  .header {
    color: #232226;
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
}

.mediumSingleSelector {
  .MuiInputBase-root {
    background: white;
    height: 56px;
    border-radius: 16px !important;
  }

  .MuiFormLabel-root {
    bottom: 0;
    transform: translate(0, 26%);
    padding-left: 24px;
  }
}

.bigSingleSelector {
  .MuiInputBase-root {
    background: white;
    height: 76px;
    border-radius: 16px !important;
  }

  .MuiFormLabel-root {
    bottom: 0;
    transform: translate(0, 33%);
    padding-left: 24px;
  }

  .MuiAutocomplete-root {
    height: unset !important;
  }
}

.bigDate {
  .MuiInputBase-root {
    background: white;
    height: 76px;
    border-radius: 16px !important;
  }

  .MuiFormLabel-root {
    bottom: 0;
    transform: translate(0, 33%);
    padding-left: 24px;
  }
}

.bigSelector {
  font-size: 16px !important;
  border-radius: 16px !important;
  height: unset !important;
  color: #232226 !important;
  max-height: unset !important;
  align-items: center;
  padding: 21px 24px !important;

  &.selectWrapper {
    border-radius: 16px !important;
  }

  #customized-hook-demo {
    height: unset !important;
  }

  ::placeholder {
    color: #6D6D6D;
    opacity: 1; /* Firefox */
  }

  ::-ms-input-placeholder { /* Edge 12 -18 */
    color: #6D6D6D;
  }
}

.disabledBtn {
  background: #4f4f4f;
}

.input-text {
  @extend .input-select;

  &.mini {
    .MuiInputBase-input {
      background-color: white !important;
      border-radius: 13px !important;
      padding-top: 8px !important;
      padding-bottom: 8px !important;
      padding-left: 10px !important;
      padding-right: 10px !important;
      font-size: 12px !important;
      font-weight: 700 !important;
    }
  }

  &.disabled {

    .MuiOutlinedInput-root {
      background-color: #EEEEEEAA !important;
    }

    input {
      background-color: transparent !important;
    }

    fieldset {
      border-color: $color-grey !important;
    }
  }

  input.MuiInputBase-input {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
}

.MuiInputBase-root:has(> .purple-border-round16) {
  border-radius: 16px !important;

  fieldset {
    border-color: $color-purple !important;
  }
}

