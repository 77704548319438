

.availability {
  border-radius: 8px;
  padding: 4px 48px;

  color: var(--content-button-primary, #FFF);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 133.333% */

  &.available {
    background-color: #05D490;
  }
}

.statusDot {
  padding: 3px;
  border-radius: 999px;
  background-color: white;
  position: absolute;
  bottom: 0px;
  right: 10px;

  div {
    width: 14px;
    height: 14px;
  }

  .available {
    border-radius: 999px;
    background-color: #05D490;
  }

  .away {
    border-radius: 999px;
    background-color: #999999;
  }
}

.editButton {
  position: absolute;
  height: 24px;
  width: 24px;
  top: 0px;
  left: -2px;
  border-radius: 99px;
  border: 1px solid var(--main-brand-colors-primary, #5658FF);
  background: var(--content-text-hover-secondary, #EDEDFF);
  display: flex;
  padding: 3px;
}

.leftCard{

}


h1{
  color: var(--color-palette-text-1, #232226);
  font-family: Surt;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
}
h2{
  color: var(--color-palette-text-1, #232226);
  font-family: Surt;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
h3{
  color: var(--color-palette-text-1, #232226);
  font-family: Surt;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
}
h4{
  color: var(--color-palette-text-1, #232226);
  font-family: Surt;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
}
h5{
  color: var(--content-text-secondary, #6D6D6D);
  font-family: Surt;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 200% */
}

.header{
  color: var(--color-palette-text-1, #232226);
  text-align: center;
  font-family: Surt;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
}

.skills {
  color: var(--color-palette-text-1, #232226);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
