.title {
  color: var(--color-palette-text-1, #232226);
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
}

.description {
  color: var(--color-palette-sm-5, #999);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.detailTitle {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
}

.detailText {
  color: var(--color-palette-text-1, #232226);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
}

.skills {
  color: var(--color-palette-text-1, #232226);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.tebriksDetay{
  color: var(--color-palette-text-1, #232226);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}
