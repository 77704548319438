$color-dark-purple: #4b4dff;
$color-purple: #5658ff;
$color-scroll-purple: #8485FA;
$color-lightest-purple: #f6f6ff;
$color-dark-grey: #525252;
$color-grey: #6d6d6d;
$color-light-grey: #999999;
$color-lightest-grey: #D6D6D6;
$color-grey4: #F5F6F3;
$color-light-red: #FD7571;
$color-black: #232226;
$color-green: #05d490;
$color-mindaro: #CFFC83;
$color-light-purple: #ededff;
$color-faded-purple: #b8b9f7;
$color-disabled-grey: #00000033;
$breakpoint-desktop: 1023px;
