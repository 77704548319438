@import "../../styles/variables.scss";

.profileCard {
  @apply rounded-[16px] overflow-hidden relative w-[240px];
  foo: bar;

  .completeProfileCard {
    @apply bg-[#EDEDFF] p-[24px];
    foo: bar;

    :global .MuiLinearProgress-root {
      height: 6px;
      background-color: #fff;

      .MuiLinearProgress-bar {
        background-color: $color-purple;
      }
    }

    .completeInfoDiv {
      @apply pt-24 w-full flex justify-center;
      foo: bar;
      color: $color-purple;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
    }
  }
}

.jobCard {
  @apply px-[36px] py-[24px] flex flex-col gap-[12px] h-full;
  background-color: $color-lightest-purple;
  border-radius: 16px;
}

.content {

  h1 {
    display: flex;
    color: #000;
    text-align: center;
    font-family: Surt;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
  }

  h2 {
    @apply flex items-center;

    color: var(--content-text-primary, #232226);
    font-family: Surt;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
  }

  h3 {
    @apply flex items-center;
    color: var(--content-text-secondary, #6D6D6D);
    text-align: center;
    font-family: Surt;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 200% */
  }

  h4 {
    color: var(--content-text-secondary, #6D6D6D);
    font-family: Surt;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 200% */
  }

  .location {
    color: #000;
    font-family: Surt;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
  }

  .description {
    color: #232226;
    font-family: Surt;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
  }

}

.socialmedia {
  @apply w-[40px] h-[40px];
  border-radius: 8px;
  border: 1px solid var(--stroke-separator, #EDEDFF);
  background: #F6F6FF;
  box-shadow: 0px 20px 80px 0px #EBECF0;
  padding: 6px 8px 9px 7px;
}
