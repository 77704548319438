.faq-page-link {
  color: #05d490;
  text-decoration: underline;
  font-weight: 600;
}

.header-contact {
  color: #5658ff;
  font-weight: 600;
}

.contact-form-area,
.establishment-form-area {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px 25px;
}

.establishment-form-area {
  height: 500px;
}

.contact-title {
  color: #5658ff;
  font-weight: 600;
}

.mails-area {
  justify-content: space-between;
  background-color: #fff;
  margin-right: 5px;
  margin-bottom: 10px;
  padding: 15px 0;
  border-radius: 10px;
}

.title-area {
  color: #5658ff;
}

.mail-address {
  color: #05d490;
}

.contact-page-title {
  width: 30%;
}

.form-control {
  border-color: #5658ff;
}

.submit-button {
  margin-top: -30px;
  padding: 10px;
}

.mails-area.selected {
  border: 0.25px solid;
  border-color: #05d490;
}
.mails-area img {
  width: 30%;
}
.basin-img {
  width: 65% !important;
}
.uzman-icon {
  width: 20% !important;
}
.company-info {
  color: #5658ff;
  font-size: 1.2rem;
  font-weight: 600;
}

.sub-title {
  font-size: 1rem;
  color: #fd7672;
}

.company-types-link {
  color: #05d490;
}

.feature-link {
  color: #5658ff;
}

.pr-info {
  font-size: 0.8rem;
}

.packet-area {
  background-color: #5658ff;
}

.packet-area div {
  display: flex;
  justify-content: center;
}

.packet-area .title {
  font-size: 1.2rem;
  color: #05d490;
}

.packet-area .subtitle {
  font-size: 0.8rem;
  color: #05d490;
}

.packet-area .short-info {
  color: #fff;
  font-size: 0.8rem;
}

.card-body-title,
.card-body-pr {
  font-size: 0.8rem;
  text-align: center;
  margin-bottom: 10px;
}

.card-body-title {
  font-weight: 600;
  color: #5658ff;
}
.modal-subtitle,
.modal-info {
  font-size: 0.9rem;
  text-align: left;
  margin-bottom: 10px;
}
.modal-info {
  font-size: 0.8rem;
  margin-bottom: 40px;
}
.modal-content {
  padding: 15px 30px;
}
.terms-conditions-area {
  height: 150px;
  border-radius: 10px;
  overflow-y: auto;
  text-align: left;
  font-size: 0.9rem;
  border: 1px solid #000;
  padding: 15px;
  border-color: #5658ff;
}
.register-submit-btn {
  padding: 7px 20px;
}

.blog-page-content {
  display: block;
}
.blog-page-content h1 {
  text-align: center;
  color: #5658ff;
  font-weight: bold;
}
.blog-page-content h2 {
  padding: 5px 0 20px;
  font-size: 25px;
  color: #5658ff;
  font-weight: bold;
}
.blog-page-content h3 {
  padding: 5px 0 20px;
  font-size: 22px;
  color: #5658ff;
  font-weight: bold;
}
.blog-page-content p {
  font-size: 14px;
  color: #000;
  font-family: sr;
  line-height: 15px;
  margin-bottom: 15px;
}

.blog-page-image {
  text-align: center;
  margin: 40px auto 50px;
  max-width: 500px;
}
.blog-page-image img {
  width: 100%;
}

.answers-side h3 {
  padding: 20px 0 20px;
  font-size: 22px;
  color: #5658ff;
  font-weight: bold;
}
.answers-side p {
  padding: 5px 0 20px;
  font-size: 14px;
}

.job-list {
  display: BLOCK;
  margin: 70px auto 0;
  text-align: center;
}

.job-list-header {
  color: #5a38fd;
}

.job-list-row {
  justify-content: center;
}

.go-job {
  background-color: #05d490 !important;
  color: #fff !important;
}

.go-job:hover {
  background-color: #fd7571 !important;
}

.job-box {
  text-align: center;
  display: block;
  background-color: #fff;
  border: 1px solid #5a38fd;
  border-radius: 10px;
  padding: 10px 10px;
  box-sizing: border-box;
  margin: 10px 10px;
  max-width: 30%;
  font-family: sm;
}

.inner-job-list a,
inner-job-list div {
  margin: 5px;
}

.inner-page h1 {
  margin-bottom: 30px;
}

.inner-page ul li {
  list-style: square;
}
.inner-page ul {
  margin-left: 20px;
}

.answers-side ul li h3 strong {
  margin-bottom: 0 !important;
}

.answers-side ul li ul li {
  padding: 10px 0 !important;
}

.answers-side ul li ul li strong {
  margin-bottom: 20px !important;
}

.inner-support-page p {
  margin-bottom: 20px !important;
}

h1.default-headline {
  padding-bottom: 40px;
  text-align: center;
  color: #5658ff;
  font-family: ssb;
  margin-bottom: 30px;
}

h1.default-headline.login {
  padding-bottom: 20px;
  text-align: center;
  color: #5658ff;
  margin-bottom: 0;
  font-family: ssb;
  padding-top: 50px;
}

h1.default-headline.blog {
  padding-bottom: 10px;
  text-align: left;
  color: #5658ff;
  margin-bottom: 0;
  font-family: ssb;
  padding-top: 10px;
}
.blog-page-content p {
  padding: 10px 0;
  line-height: 25px;
}

.support-center-area {
  justify-content: center;
  margin: 20px auto;
}

.pricing-img-sec {
  margin: 0 30px;
}

.pricing-img-sec img {
  width: 100%;
}

.pricing-info ul li {
  padding: 10px 0;
}

@media (max-width: 767px) {
  .pricing-title {
    padding: 20px 25px;
  }
  .establishment-page .row {
    margin: 0 !important;
    text-align: center;
  }
  .container.establishment-page br {
    display: none;
  }
  body .single-why-pofft:nth-child(even) .why-text {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  body #footer .upper-footer .inner-upper-footer .col-md-3 {
    padding: 0 15px !important;
  }
  body .single-why-pofft {
    margin-bottom: 0 !important;
  }
  .home-banner {
    display: block !important;
    text-align: center;
  }
  body #footer .upper-footer .inner-upper-footer .footer-part {
    min-height: 150px !important;
  }
  .job-box {
    max-width: 100%;
  }
  body .testimonies .inner-test .test-image {
    margin: 20px auto !important;
  }
  body .testimonies .inner-test {
    text-align: center;
  }
  body .subscribe-section .input-area button {
    position: relative !important;
    bottom: -10px !important;
  }
  body #footer .upper-footer .inner-upper-footer .footer-sm ul {
    margin: 0 80px;
  }
  .mails-area {
    margin-right: -15px;
  }

  .contact-form-area {
    margin-top: 15px;
  }

  .contact-page-title {
    width: 100%;
  }

  .desktop-name-area {
    display: none;
  }

  .establishment-form-area,
  .establishment-mails-area {
    height: auto;
  }

  .contact-title {
    font-size: 1.4rem;
  }

  .terms-conditions-area {
    height: 250px;
  }

  .partner-form {
    display: block !important;
  }

  .partners-text {
    margin-bottom: 25px;
  }

  body #header .downer-header .downer-upper .nav ul li {
    padding: 10px;
  }

  .blog-area-img {
    width: 100%;
    height: auto;
    margin: 20px auto;
  }
}

@media (min-width: 768px) {
  .aside-blog {
    margin-left: 40rem;
  }

  .home-partner-item {
    float: left;
    margin-right: 92px;
    margin-top: 15px;
    margin-bottom: 40px;
  }

  .home-partners {
    overflow-x: auto;
  }

  .home-partner-title {
    margin-right: 50px;
  }

  .partners-photo {
    padding-right: 30px;
  }

  .blog-title-area {
    padding-bottom: 10px;
    border-bottom: 1px solid #999999;
    margin-left: unset;
  }

  .blog-area-img {
    max-width: 350px;
  }

  .blog-list.line-common {
    background-color: #f6f6ff;
    margin-right: 100px;
    margin-left: 100px;
  }

  .footer-long-info {
    margin-top: -75px;
  }

  .mobile-name-area {
    display: none;
  }

  .mails-area {
    min-height: 155px;
  }

  .establishment-page,
  .contact-page {
    background-color: #f6f6ff;
    padding: 60px !important;
  }

  .info-area-right {
    padding-right: 75px;
  }

  .info-area-right {
    padding-right: 75px;
  }

  .modal-content {
    margin-top: 20rem;
  }

  .terms-conditions-area {
    height: 150px;
  }

  .modal-footer {
    border: none;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .profile-page {
    flex: 100% !important;
  }

  .messages-list {
    height: 650px !important;
  }

  .single-message {
    margin-right: 15rem;
    margin-bottom: 15px !important;
  }

  .single-message.sender {
    margin-right: 0;
    margin-left: 15rem;
  }

  .partner-form {
    width: 50%;
  }

  .single-paretners-main {
    width: 50%;
  }

  .new-partner-area {
    width: 47%;
    height: 250px;
  }
  /* .why-text p:nth-child(1) {
        width: 400px;
    }
*/
  .why-text p:nth-child(2) {
    /*width: 600px;*/
    width: auto;
  }

  .pricing-card {
    width: 47%;
  }

  .pricing-info {
    min-height: 650px;
    position: relative;
  }

  .pricing-buttons {
    position: absolute;
    bottom: -10px;
  }

  .logo {
    padding-right: 40px !important;
  }

  .logo a img {
    width: 150px !important;
  }
}

.pricing-info {
  background-color: #5658ff;
  color: #000;
  border-radius: 0 0 0.5rem 0.5rem;
}
.pricing-title {
  background-color: #fff;
  padding: 20px 85px;
  text-align: center;
  font-weight: 600;
  border-radius: 0.5rem 0.5rem 0 0;
  color: #5658ff;
}
.pricing-buttons {
  background-color: #91ffa7;
  width: 100%;
  text-align: center;
  padding: 17px;
  border-radius: 0 0 0.5rem 0.5rem;
}
.pricing-img {
  width: 100%;
}
.profile-page .chat-area .chat-type-area .chat-type-holder {
  border: none !important;
}
.profile-page .chat-type-area .chat-type-holder .input-section input {
  border: 1px solid #5658ff !important;
  border-radius: 3px !important;
}
.profile-page .chat-type-area .chat-type-holder .input-section {
  padding-left: unset !important;
  padding-right: 30px !important;
}
.profile-page .pofft-btns {
  border-radius: 5px;
}

.crud-form-body {
  background: #f6f6ff;
  padding: 60px;
}

.offer-details-area {
  text-align: center;
}
.project-status {
  color: #5658ff;
}
.comments-about-project {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  min-height: 150px;
  padding: 15px;
  background: #5658ff;
  border-radius: 0 15% 0 15%;
  width: 45%;
  color: #fff;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
}
.comments-title {
  font-size: 1.1rem;
}
.price-info {
  color: #05d490;
  font-size: 1.1rem;
  font-weight: 600;
}
body #header .upper-header .inner-upper .moto {
  width: 73%;
}
.single-message .message-content p {
  word-break: break-word;
}
.test-text p:nth-child(2) {
  margin-top: 15px !important;
  font-size: 18px !important;
  color: #5658ff !important;
  font-weight: bold;
}
.test-text p:nth-child(3) {
  font-size: 15px !important;
}
.why-text p strong span {
  font-size: 22px !important;
}
.why-text p span {
  font-size: 22px !important;
  font-weight: bold;
}
.why-text p:nth-child(1) {
  padding-top: 30px;
  border-top: 2px solid #5658ff;
}
.work-btns button {
  padding: 10px 35px;
}
.detail-close-btn {
  position: absolute;
  top: 25px;
  right: 25px;
}
.support-center-area li {
  margin-bottom: 35px;
  text-align: center;
}
.prof-img img {
  width: 100% !important;
  height: 100% !important;
}
.uploaded .uploaded-image {
  width: 150px !important;
  height: 150px !important;
}
.right-capctha div:nth-child(1) {
  float: right;
  margin-bottom: 25px;
}
.partnets-holder {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.new-partner-area {
  margin-bottom: 35px;
  padding: 25px 50px;
  border-radius: 1rem;
}
.partnets-holder .new-partner-area:first-child {
  background-color: #befcd9;
}
.partnets-holder .new-partner-area:nth-child(2) {
  background-color: #c7bfdf;
}
.partnets-holder .new-partner-area:nth-child(3) {
  background-color: #f9a5a5;
}
.partnets-holder .new-partner-area:nth-child(4) {
  background-color: #e3f9b9;
}
.partner-info strong {
  font-size: 1.2rem;
}
body .testimonies .inner-test .test-text p {
  margin-top: 45px;
  margin-bottom: 0;
}
body .testimonies .inner-test .test-image {
  flex: 0 0 30%;
  max-width: 250px;
  margin: 20px;
}
.carousel-indicators li.active {
  background-color: #f87a6f;
}
body .testimonies .inner-test {
  border-radius: 1rem;
}
.form-features {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.feature-item {
  display: flex;
  justify-content: center;
  align-items: baseline;
}
.home-partners .partnet-item img {
  width: 170px;
  height: 90px;
}
.cookie-banner {
  right: inherit;
  max-width: 580px;
  position: fixed;
  left: 24px;
  bottom: 24px;
  right: 16px;
  z-index: 2;
}
.cookie-banner .content {
  display: flex;
  flex-flow: row;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.33;
  color: #fff;
  padding: 16px;
  background-color: #343248;
}
.new-partner-item {
  margin-top: 25px;
}
.external-login.facebook img {
  background: #fff;
}
.footer-long-info img {
  width: 350px;
  margin: 0 auto;
  display: block;
}

/**cd*/

.status_message_box img {
  width: 100%;
}
